<template>
  <div style="background-color: whitesmoke">
    <van-nav-bar
        v-if="!isInMini()"
        id="nav-bar"
        title="请选择格口"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <van-empty v-if="lockerId == null && lockerList != null && lockerList.length === 0" description="此区域没有保温柜" :style="'height: ' + emptyHeight + 'px'" />
    <van-tabs id="tabs" v-model="active" v-if="lockerId == null && lockerList != null && lockerList.length > 0">
      <van-tab v-for="locker in lockerList" :key="locker.id" :title="locker.lockerName"></van-tab>
    </van-tabs>
    <div id="cell_type_tips" style="display: flex; margin: 10px 15px" v-if="lockerId != null || (lockerList != null && lockerList.length > 0)">
      <div class="dot_tips"><div class="large_cell_dot"></div>大格子</div><div class="dot_tips"><div class="small_cell_dot"></div>小格子</div>
    </div>
    <div id="scroll-div" style="overflow: scroll; display: flex; flex-wrap: wrap; justify-content: center; align-content: flex-start">
      <div @click="onClickCompartment(cell)" :class="cell.class" v-for="(cell, index) in compartmentList" :key="index" style="flex-basis: 21%; position: relative; margin: 1.1%">
        <div :class="cell.compartmentType === 'LARGE' ? 'large' : 'small'" style="display: flex; align-items: center; justify-content: center; position: absolute; margin-left: 50%; transform: translate(-50%, 9px)">{{cell.compartmentNo}}</div>
        <van-image v-if="cell.openHeat" :src="require('@/assets/locker/jiare_icon.png')" width="9" height="10" style="float: right; position:relative; margin: 6px"/>
      </div>
    </div>
    <div v-if="lockerId != null || (lockerList != null && lockerList.length > 0)" id="bottom-view" class="sumbit-button">
      <div style="display: flex;justify-content: space-between;padding: 0 15px; align-items: center;font-size: 15px;font-family: PingFang SC;font-weight: 500;color: #333333;">
        <span>数量：{{ cellAmount() }}</span>
        <div>合计：<span style="color: #BD0404">¥{{totalMoney()}}</span></div>
        <van-button @click="clickSubmit" color="#FFBD04" block style="width: 135px" round>确认订单</van-button>
      </div>
    </div>
    <van-dialog v-model="showHeatOptions" :title="selectedLocker.lockerName + selectedCell.compartmentName + ''" :show-confirm-button="false">
      <div style="display: flex; padding: 35px 0; justify-content: space-evenly; align-items: center">
        <div @click="onClickHeat(true)" :class="selectedCell.openHeat ? 'heat_option_selected' : 'heat_option_normal'" style="position: relative">
          <span style="line-height: 45px; position:absolute; margin-left: 50%; transform: translate(-50%, 0)">加热</span>
          <van-image v-if="selectedCell.openHeat" :src="require('@/assets/locker/xuanzhong_icon.png')" width="28" height="28" style="float: right; position: relative;"/>
        </div>
        <div @click="onClickHeat(false)" :class="selectedCell.openHeat ? 'heat_option_normal' : 'heat_option_selected'" style="position: relative">
          <span style="line-height: 45px; position:absolute; margin-left: 50%; transform: translate(-50%, 0)">不加热</span>
          <van-image v-if="!selectedCell.openHeat" :src="require('@/assets/locker/xuanzhong_icon.png')" width="28" height="28" style="float: right; position: relative;"/>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Client from "@/api";

export default {
  name: "Locker",
  data: function () {
    return {
      active: 0,
      lockerList: null,
      selectedLocker: {},
      selectedCell: {},
      showHeatOptions: false,
      compartmentList: [],
      lockerId: null,

      emptyHeight: 0
    }
  },
  mounted() {
    let navbar = document.getElementById('nav-bar')
    let navHeight = navbar ? navbar.clientHeight : 0
    // alert(navHeight)
    this.emptyHeight = window.innerHeight - navHeight

    let lockerId = this.$route.query.lockerId
    if (lockerId && lockerId !== '') {
      // 39babde0-1cda-11eb-a0b8-d6a09c2d0fc4
      this.lockerId = lockerId
      localStorage.removeItem('orderId')
      this.getLocker(lockerId)
    } else {
      this.loadLocker()
    }
  },
  watch: {
    active() {
      console.log(this.active)
    }
  },
  methods: {
    loadLocker() {
      Client.get('locker', {params: {userAreaId: localStorage.getItem('userAreaId')}}).then(res => {
        if (res.data.state === 'ok') {
          this.lockerList = res.data.list
          if (this.lockerList.length > 0) {
            this.selectedLocker = this.lockerList[0]
            this.loadCompartment(this.selectedLocker.id)
            this.$nextTick(() => {
              this.setScrollViewHeight()
            })
          }
        } else {
          this.$toast.fail(res.data.msg)
        }
      })
    },
    getLocker(lockerId) {
      Client.get('locker/' + lockerId).then(res => {
        if (res.data.state === 'ok') {
          this.selectedLocker = res.data.data
          document.title = this.selectedLocker.lockerName
          this.loadCompartment(this.selectedLocker.id)
          this.$nextTick(() => {
            this.setScrollViewHeight()
          })
        } else {
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.removeItem('token')
          this.navTo('/entry')
        } else {
          this.$toast.fail(err.response.data.msg)
        }
      })
    },
    loadCompartment(lockerId) {
      Client.get('compartment', {params: {lockerId: lockerId}}).then(res => {
        if (res.data.state === 'ok') {
          this.compartmentList = res.data.list
          this.compartmentList.forEach(item => {
            item.selected = false
            item.openHeat = false
            if (item.lockStatus === 'LOCKED' || item.status === 'BLOCK') {
              item.style = 'background-color: #ffc1c1'
              item.class = 'cell_block'
            } else {
              item.style = ''
              item.class = 'cell_normal'
            }
          })
        } else {
          this.$toast.fail(res.data.msg)
        }
      })
    },
    onClickCompartment(compartment) {
      console.log(compartment.id)
      if (compartment.lockStatus === 'FREE' && compartment.status === 'NORMAL') {
        compartment.selected = !compartment.selected;
        this.selectedCell = compartment
        if (compartment.selected) {
          compartment.class = 'cell_selected'
          this.showHeatOptions = true
        } else {
          compartment.class = 'cell_normal'
          compartment.openHeat = false
        }
        this.$forceUpdate()
        // console.log(compartment.class)
      }
    },
    onClickHeat(heat) {
      this.selectedCell.openHeat = heat
      this.showHeatOptions = false
      // this.$forceUpdate()
    },
    setScrollViewHeight() {
      let navHeight = document.getElementById('nav-bar') ? document.getElementById('nav-bar').clientHeight : 0
      let tabsHeight = document.getElementById('tabs') ? document.getElementById('tabs').clientHeight : 0
      let bottomHeight = document.getElementById('bottom-view') ? document.getElementById('bottom-view').clientHeight : 0
      let cellTypeTipsHeight = document.getElementById('cell_type_tips').clientHeight
      console.log(navHeight, tabsHeight, bottomHeight)
      document.getElementById('scroll-div').style.height = window.innerHeight - navHeight - tabsHeight - bottomHeight - cellTypeTipsHeight - 20 + 'px'
    },
    cellAmount() {
      return this.compartmentList.filter(item => item.selected).length
    },
    totalMoney() {
      let total = 0
      /*for (let cell of this.compartmentList.filter(item => item.selected)) {
        // if (cell.compartmentType === 'SMALL') {
        //   if (cell.openHeat) total += 60
        //   else total += 50
        // } else {
        //   if (cell.openHeat) total += 80
        //   else total += 70
        // }
        if (cell.compartmentType === 'SMALL') {
          if (cell.openHeat) total += 10
          else total += 10
        } else {
          if (cell.openHeat) total += 10
          else total += 10
        }
      }*/
      return (total / 100).toFixed(2)
    },
    clickSubmit() {
      let selectedList = this.compartmentList.filter(item => item.selected)
      if (selectedList.length === 0) {
        this.$dialog.alert({message: '请先选择需要存放的格子', confirmButtonText: '好的'})
      } else {
        let orderId = localStorage.getItem('orderId') || ''
        let compartmentList = selectedList.map(compartment => { return {id: compartment.id, heatStatus: compartment.openHeat ? 'ON' : 'OFF'} })
        let body = {compartmentList}
        if (orderId.length > 0) {
          body.orderId = orderId
        } else {
          if (this.lockerId != null) {
            body.lockerId = this.lockerId
          } else {
            body.areaId = localStorage.getItem('userAreaId')
          }
        }
        this.$toast.loading()
        Client.post('courierOrder', body).then(res => {
          this.$toast.clear()
          if (res.data.state === 'ok') {
            // localStorage.removeItem('orderId')
            localStorage.setItem('cabinet', JSON.stringify(this.selectedLocker))
            localStorage.setItem('cellList', JSON.stringify(selectedList))
            localStorage.setItem('order', JSON.stringify({createTime: res.data.data.createTime, courierOrderId: res.data.data.id, orderSn: res.data.data.orderSn, takeCode: res.data.takeCode, areaName: res.data.data.areaName, userOrderId: orderId}))
            this.navTo('/pay')
            // this.$router.push({path: '/pay', query: {createTime: res.data.data.createTime, courierOrderId: res.data.data.id, orderSn: res.data.data.orderSn, takeCode: res.data.takeCode, areaName: res.data.data.areaName}})
          } else {
            this.$toast.fail(res.data.msg)
          }
        }).catch(err => {
          this.$toast.clear()
          this.$dialog.alert({message: err.response.data.msg})
        })
      }
    }
  }
}
</script>

<style scoped>
.large_cell_dot {
  width: 10px;
  height: 10px;
  background: #999999;
  border-radius: 50%;
  margin-right: 4px;
}
.small_cell_dot {
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border: 1px solid #999999;
  margin-right: 4px;
  margin-left: 24px;
  border-radius: 50%;
}
.dot_tips {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.cell_normal {
  height: 48px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #FFFFFF;
}
.cell_block {
  height: 48px;
  background: #E4E4E4;
  border-radius: 4px;
  border: 2px solid #E4E4E4;
}
.cell_block .large {
  width: 30px;
  height: 30px;
  background: #CCCCCC;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #E4E4E4;
  line-height: 9px;
}
.cell_block .small {
  width: 30px;
  height: 30px;
  background: #E4E4E4;
  border: 1px solid #CCCCCC;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #CCCCCC;
  line-height: 9px;
}
.cell_selected {
  height: 48px;
  background: #FFFFFF;
  border: 2px solid #FFBD04;
  border-radius: 4px;
}
.cell_normal .large {
  width: 30px;
  height: 30px;
  background: #999999;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 9px;
}
.cell_normal .small {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #999999;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #999999;
  line-height: 9px;
}
.cell_selected .large {
  width: 30px;
  height: 30px;
  background: #FF6204;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 9px;
}
.cell_selected .small {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #FF6204;
  border-radius: 50%;

  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #FF6204;
  line-height: 9px;
}

.heat_option_normal {
  width: 120px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  line-height: 19px;
}
.heat_option_selected {
  width: 120px;
  height: 45px;
  background: #FFE8C5;
  border-radius: 5px;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FF6204;
  line-height: 19px;
}
/deep/ .van-dialog__header {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
/deep/ .van-tabs__wrap {
  padding-bottom: 10px;
}
/deep/ .van-tab {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 25px;
}
/deep/ .van-tab--active {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
}
/deep/ .van-tabs__line {
  width: 18px;
  height: 5px;
  background: linear-gradient(90deg, #FFBD04 0%, #FFD04D 100%);
  border-radius: 3px;
}
/deep/ .van-tabbar-item__text {
  font-size: 11px;
}
.compartment {
  display: flex;
  flex-direction: column;
  /*background-color: #f59595;*/
  width: 100%;
  color: grey;
  padding: 8px 0;
  align-items: center;
  justify-content: center;
}

/deep/ .van-grid-item__content {
  padding: 0;
}
.sumbit-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  background-color: white;
  z-index: 100000;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}
</style>
